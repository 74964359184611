footer {
    background: #001547;
    padding: 75px;
}

footer img {
    padding-bottom: 15px;
    aspect-ratio: attr(width) / attr(height);
}

footer .copyright {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 34px;
    align-items: center;
    text-align: center;
    color: #BDBDBD;
}

footer .image-col {
    margin-left: auto;
    margin-right: auto;
}