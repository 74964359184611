.about .about-left {
    background-image: url(./images/about.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-clip: unset;
    background-size: contain;
    min-height: 500px;
}

.about .about-right {
    margin-top: 75px;
}

@media (max-width: 768px) {
    .about .about-right {
        margin-top: 30px;
    }
}