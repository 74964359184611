section#services {
    text-align: center;
}

.services {
    margin-top: 45px;
}

.service-callout {
    margin: auto;
}

.service-callout .service .details {
    margin-top: -45px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 31px;
    margin-bottom: 50px;
    padding: 25px;
    padding-top: 75px;
}

.service-callout .service .circle {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 45px;
    line-height: 90px;
    font-size: 45px;
}

.service-callout .service .circle.orange {
    background: linear-gradient(142.72deg, #FFD438 4.32%, #E3B200 94%);
    box-shadow: 0px 13px 34px rgba(255, 212, 56, 0.33);
}

.service-callout .service .circle.green {
    background: linear-gradient(142.72deg, #00DD1F 4.32%, #02A719 94%);
    box-shadow: 0px 13px 34px rgba(0, 221, 31, 0.33);
}

.service-callout .service .circle.blue {
    background: linear-gradient(142.72deg, #50CEF5 4.32%, #13B7FD 94%);
    box-shadow: 0px 13px 34px rgba(80, 206, 245, 0.33);
}