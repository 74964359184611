.hero {
    margin-top: 50px;
}

.hero h1 {
    text-align: left;
    font-family: 'MazzardH-SemiBold', sans-serif;
}

.hero p {
    text-align: left;
}

.hero a {
    float: left;
    margin-top: 35px;
}

.hero .hero-content {
    padding-top: 100px;
}

.hero .hero-image {
    background-image: url('./images/hero.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-clip: unset;
    background-size: contain;
    overflow: hidden;
}

.hero .hero-image.before {
    display: none;
}

.hero .light-effect {
    position: absolute;
    width: calc(250px + 100vw - 1024px);
    height: 692.07px;
    right: -200px;
    top: -300px;
    background: linear-gradient(180deg, rgba(20, 153, 252, 0.2) 0%, rgba(20, 153, 252, 0) 100%);
    transform: rotate(49.01deg);
    z-index: -1;
}

@media (max-width: 768px) {
    .hero {
        margin-top: 20px;
    }
    .hero h1 {
        text-align: center;
    }
    .hero .hero-content {
        padding-top: 25px;
    }
    .hero .hero-image {
        height: 350px;
        background-clip: content-box;
    }
    .hero .hero-image.before {
        display: block;
    }
    .hero .hero-image.after {
        display: none;
    }
    .hero .light-effect {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .hero .light-effect {
        right: -200px;
        top: -200px;
    }
}

@media (max-width: 1399.98px) {
    .hero .light-effect {
        right: -300px;
    }
}