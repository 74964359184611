.contact .form-container {
    margin-left: auto;
    margin-right: auto;
    padding: 75px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 12px 43px 250px rgba(145, 150, 254, 0.08);
    border-radius: 52px;
}

.contact .errorMsg {
    color: rgb(236, 89, 144);
}

.contact .Msg {
    display: none;
}

.contact .successMsg {
    color: #00DD1F;
}

.contact button+.successMsg,
.contact button+.errorMsg {
    margin-top: 10px;
}

.contact form {
    padding-top: 35px;
}

.contact form .form-label {
    margin: 0;
}

.contact form .form-control {
    margin-top: 0;
    margin-bottom: 15px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    padding-left: 0;
    color: #fff;
    padding: 0;
    padding-bottom: 2px;
}

.contact form .form-control:not(:empty) {
    background: transparent;
}

.contact form .form-control.error {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgb(236, 89, 144);
    border-image: initial;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: all;
}

.contact form .form-control:focus {
    background: transparent;
    box-shadow: none;
}

.contact form button {
    margin-top: 35px;
}

.contact h4 {
    margin-bottom: 25px;
}

@media (max-width: 768px) {
    .contact .form-container {
        padding: 35px;
    }
    .contact h4 {
        text-align: left;
    }
}