.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand>img {
    padding: 7px 14px;
    display: block;
    aspect-ratio: attr(width) / attr(height);
}

.navbar .navbar-toggler-icon {
    color: #fff;
}

.navbar .navbar-nav a.nav-link {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    z-index: 100;
}

.navbar-nav a.nav-link.button-shaded {
    background-color: rgba(255, 255, 255, .2);
    border-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-nav a.nav-link.button-shaded:hover {
    background-color: rgba(255, 255, 255, .5);
    color: #fff;
}

a.nav-link {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active:hover {
    color: rgba(255, 255, 255, .8)
}

.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .show>.nav-link,
.navbar .navbar-nav .nav-link:focus {
    color: #fff;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}